import { isAnonymousUser } from '../utils/isAnonymousUser';
import { useAuth } from '@mindhiveoy/react-auth';
import { useAuthentication } from '../AuthProvider';
import { useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import SignIn from 'components/auth/SignIn';
import styled from '@emotion/styled';

const SignInBackdrop = styled(Backdrop)(({
  theme,
}) => `
  background-color: rgba(0, 0, 0, 0.8); 
  z-index: ${theme.zIndex.drawer + 1},
`);

export const SignInOverlay = () => {
  const {
    user,
    authenticationActivated,
  } = useAuth();

  const {
    planId,
    signingMethod,
    closeSignInDialog,
  } = useAuthentication();

  /**
   * Close the window on escape key press
   */
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeSignInDialog();
        event.stopPropagation();
        event.preventDefault();
      }
    };
    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeSignInDialog,]);

  return isAnonymousUser(user) ?
    <SignInBackdrop
      open={!!(signingMethod !== 'none' || authenticationActivated)}
      onClick={closeSignInDialog}
    >
      {signingMethod !== 'none' ?
        <SignIn
          mode={signingMethod}
          planId={planId} /> :
        null
      }
    </SignInBackdrop> :
    null;
};

export default SignInOverlay;
